/* eslint-disable */

const MenuService = [
  {
    label: "Cadastros",
    permission: "all",
    subMenu: [
      {
        label: "Clientes",
        icon: "mdi-card-account-details-outline",
        link: "/base/customers",
        permission: "gerentor.person.customers.get_many",
      },
      {
        label: "Novo Cliente",
        icon: "mdi-card-account-details-outline",
        link: "/base/customers/create",
        permission: "gerentor.person.customers.create_one",
      },
      {
        label: "Funcionários",
        icon: "mdi-account-group",
        link: "/base/employees",
        permission: "gerentor.person.employees.get_many",
      },
      {
        label: "Usuários",
        icon: "mdi-account",
        link: "/users",
        permission: "super_admin",
      },
      {
        label: "Grupos de Usuários",
        icon: "mdi-account",
        link: "/user/group-users",
        permission: "super_admin",
      },
      {
        label: "Fornecedores",
        icon: "mdi-office-building-marker",
        link: "/base/suppliers",
        permission: "gerentor.suppliers.get_many",
      },
    ],
  },
  {
    label: "Vendas",
    permission: "gerentor.sales.get_many",
    subMenu: [
      {
        label: "Listagem de Vendas",
        icon: "mdi-sale",
        link: "/base/sales/home",
        permission: "gerentor.sales.get_many",
      },
      {
        label: "Nova Venda",
        icon: "mdi-sale",
        link: "/base/sale/create",
        permission: "gerentor.sales.create_one",
      },
      {
        label: "Faturamento",
        icon: "mdi-sale",
        link: "/base/sales/billing",
        permission: "gerentor.sales.update_one",
      },
    ],
  },
  {
    label: "Financeiro",
    permission: 'gerentor.finance.transactions.get_many',
    subMenu: [
      {
        label: "Extrato Conta",
        icon: "mdi-finance",
        link: "/finance/account-reports",
        permission: "gerentor.finance.transactions.get_many",
      },
      {
        label: "Transações",
        icon: "mdi-finance",
        link: "/finance/movements",
        permission: "gerentor.finance.transactions.get_many",
      },
      {
        label: "Contas a Receber",
        icon: "mdi-currency-usd",
        link: "/finance/report/account-receivables",
        permission: "gerentor.finance.transactions.get_many",
      },

      {
        label: "Contas a Pagar",
        icon: "mdi-currency-usd-off",
        link: "/finance/report/account-payables",
        permission: "gerentor.finance.transactions.get_many",
      },
      {
        label: "Contas Financeiras",
        link: "/finance/accounts",
        icon: "mdi-credit-card",
        permission: "gerentor.finance.accounts.get_many",

      },
      {
        label: "Classificações",
        link: "/finance/categories",
        icon: "mdi-list-status",
        permission: "gerentor.finance.categories.get_many",

      },
      {
        label: "Métodos de Pagamento",
        link: "/finance/payment-methods",
        icon: "mdi-list-status",
        permission: "gerentor.finance.payment_methods.get_many",
      },
    ],
  },
  {
    label: "Produtos",
    permission: "gerentor.products.get_many",
    subMenu: [
      {
        label: "Produtos",
        icon: "mdi-food",
        link: "/base/products",
        permission: "gerentor.products.get_many",
      },
      {
        label: "Grupos",
        link: "/base/categories",
        icon: "mdi-tag-multiple",
        permission: "gerentor.product_categories.get_many",
      },
      {
        label: "Novo Produto",
        link: "/base/product/create",
        icon: "mdi-plus-thick",
        permission: "gerentor.products.create_one",
      },   
    ],
  },
  {
    label: "Patrimonio",
    permission: "gerentor.patrimonies.get_many",
    subMenu: [ 
      {
        label: "Novo Ativo",
        link: "/asset/create",
        icon: "mdi-plus-thick",
        permission: "gerentor.patrimonies.create_one",
      },
      {
        label: "Listagem de Ativos",
        link: "/assets",
        icon: "mdi-tag-multiple",
        permission: "gerentor.patrimonies.get_many",
      },
    ],
  },
  {
    label: "Estoque",
    permission: "gerentor.stock_transactions.get_many",
    subMenu: [
      {
        label: "Transações de Estoque",
        icon: "mdi-form-select",
        link: "/base/stock-transactions",
        permission: "gerentor.stock_transactions.get_many",
      },
      {
        label: "Nova Transação de Estoque",
        icon: "mdi-plus",
        link: "/base/stock-transactions/create",
        permission: "gerentor.stock_transactions.create_one",
      },
    ],
  },
  {
    label: "Relatórios",
    permission: "all",
    subMenu: [
      {
        label: "Relatório de Comissão",
        icon: "mdi-form-select",
        link: "/gerentor/comission-transactions",
        permission: "gerentor.comission_transactions.get_many",
      },
      {
        label: "Aniversariantes",
        icon: "mdi-group",
        link: "/customers/birthdays",
        permission: "gerentor.person.customers.get_many",
      },
      {
        label: "Dashboard de Patrimônio",
        icon: "mdi-finance",
        link: "/assets/dashboard",
        permission: "gerentor.patrimonies.get_many",
      },
    ],
  },
]
let MenuFilter: any[] = []

 const GetFilteredMenu = ()=> {
  const _filtered = [];
  const us = localStorage.getItem("user");
  let user = null;
  if (us && typeof us === "string") {
    const vs = JSON.parse(us);
    user = vs;
  }
  if (user.admin) {
    MenuFilter = MenuService;
    return MenuFilter;
  }
  const permissions = user?.group?.permissions?.map((d: any) => d.id);
  if (!permissions?.length) {
    return [];
  }
  for (const item of MenuService) {
    if (
      permissions.includes(item.permission) ||
      item.permission === "all" ||
      (user && user.admin)
    ) {
      const subs = [];
      for (const sub of item.subMenu) {
        if (
          permissions.includes(sub.permission) ||
          sub.permission === "all" ||
          (user && user.admin)
        ) {
          subs.push(sub);
        }
      }
      item.subMenu = subs;
      _filtered.push(item);
    }
  }
  MenuFilter = _filtered;
  return MenuFilter;
}

const MenuSearch: any = []
const GetFilteredMenuList = ()=> {
  const _menu = GetFilteredMenu();
  _menu?.map((a:any)=> a?.subMenu?.map((b:any)=> MenuSearch.push(b)))
  }
  GetFilteredMenu()
  GetFilteredMenuList()
export {MenuFilter, MenuSearch};