<template>
  <v-row justify="center" align="center">
    <div v-for="(menu, mi) in menu" :key="mi">
      <v-menu origin="center center" transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            dark
            v-bind="attrs"
            v-on="on"
            :to="menu.link"
            style="text-transform: none !important; font-size: 11px"
          >
            {{ menu.label }}
            <v-icon v-if="menu.subMenu">mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list v-if="menu.subMenu" width="280" height="auto" style="padding: 10px">
          <v-list-item-title>{{ menu.label }}</v-list-item-title>
          <v-divider style="width: 140px !important" class="mt-2" />
          <br />
          <v-list-item v-for="(item, i) in menu.subMenu" :key="i">
            <v-btn
              class="no-active"
              text
              :to="item.link"
              style="text-transform: none !important"
            >
              <v-list-item-title style="font-size: 11px">
                <v-icon left>{{ item.icon }}</v-icon>
                {{ item.label }}</v-list-item-title
              >
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-row>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import { MenuFilter } from "./MenuService";
export default Vue.extend({
  name: "MenuDesktop",
  data: () => ({
    showMenu: false,
    disabled: false,
    categoryId: null,
    segments: [],
    loading: false,
    minMenu: [],
    menu: MenuFilter,
  }),
  methods: {},
});
</script>
<style>
.active-menu {
  background-color: white !important;
}
.v-btn--active.no-active::before {
  opacity: 0 !important;
}
</style>
