<template>
  <div>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false">Ok</v-btn>
      </template>
    </v-snackbar>
    <v-app-bar app color="#1a265a" dark elevation="0" height="75">
      <menu-mobile class="hidden-md-and-up mr-3" />
      <div class="d-flex align-center">
        <v-img
          alt="Gerentor - Gestão Inteligente"
          class="shrink"
          contain
          min-width="100"
          src="@/assets/gerentor_green.png"
          width="110"
        />
      </div>
      <menu-desktop class="hidden-sm-and-down" />
      <v-autocomplete
        rounded
        v-model="searchRoute"
        class="search-input hidden-sm-and-down"
        :items="menu"
        item-text="label"
        item-value="link"
        flat
        hide-no-data
        hide-details
        label="Menu"
        solo-inverted
        append-icon="mdi-magnify"
        @change="searchTo()"
        style="width: 120px"
      >
      </v-autocomplete>
      <v-spacer class="hidden-md-and-up" />
      <v-btn class="no-active mr-n6" text :to="user.initialRoute">
        <v-icon size="16px">mdi-home</v-icon>
      </v-btn>

      <v-btn class="mr-n4" text @click="logout">
        <v-icon size="16px">mdi-account-arrow-right-outline</v-icon>
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon size="16px">mdi-cog-outline</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item width="8rem" text style="text-decoration: none">
            <v-switch
              @change="setDark"
              v-model="user.darkTheme"
              label="Modo Escuro"
            ></v-switch>
          </v-list-item>
          <v-list-item width="8rem" text style="text-decoration: none">
            <v-btn
              block
              style="text-decoration: none"
              color="green"
              dark
              small
              @click="openChangeDialog"
              >Trocar Senha</v-btn
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-dialog width="400" v-model="changePasswordDialog">
      <v-container>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title class="overline">Trocar Senha</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card style="padding: 16px">
          <v-card height="80px" v-if="forcePass" class="d-flex justify-center">
            <p>{{ messagePass }}</p>
          </v-card>

          <v-card height="30px" v-if="loadingAction" class="d-flex justify-center">
            <p>Estamos processando seus dados. Aguarde...</p></v-card
          >
          <v-form>
            <br />
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  v-model="pass.oldPass"
                  placeholder="Senha Atual"
                  type="password"
                  label="Senha Atual"
                  :rules="[(d) => !!d || 'Senha Atual é requerido']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-n8">
                <v-text-field
                  outlined
                  type="password"
                  v-model="pass.newPass"
                  placeholder="Nova Senha"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-card-actions class="d-flex justify-end">
              <v-row>
                <v-col cols="12">
                  <v-btn
                    :loading="loadingAction"
                    block
                    depressed
                    color="success"
                    @click="changePassword"
                    >Enviar</v-btn
                  >
                </v-col>
                <v-col cols="12">
                  <v-btn
                    outlined
                    block
                    depressed
                    color="primary"
                    @click="
                      changePasswordDialog = false;
                      pass.oldPass = '';
                      pass.newPass = '';
                    "
                    >Cancelar</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>
<script>
/*eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import MenuDesktop from "./MenuDesktop.vue";
import MenuMobile from "./MenuMobile.vue";
import { MenuSearch } from "./MenuService";
export default Vue.extend({
  components: { MenuDesktop, MenuMobile },
  name: "Navbar",
  data: () => ({
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    searchRoute: "",
    visibleTitle: true,
    logged: false,
    user: {
      visibleMenu: true,
      darkTheme: false,
      admin: false,
      initialRoute: "/",
      group: {
        permissions: [],
      },
    },
    loadingAction: false,
    changePasswordDialog: false,
    messagePass: "",
    forcePass: false,
    pass: {
      oldPass: "",
      newPass: "",
    },
    menuFilter: [],
    menu: MenuSearch,
  }),
  methods: {
    searchTo() {
      this.$router.push(this.searchRoute);
    },
    logout() {
      localStorage.clear();
      this.logged = false;
      this.$router.push("/login");
    },
    setDark(isDark) {
      const payload = {
        isDark,
      };
      http.put("users/me/preferences", payload).then((res) => {
        const { data } = res;
        localStorage.setItem("user", JSON.stringify(data));
        location.reload();
      });
    },

    init() {
      const us = localStorage.getItem("user");
      if (us && typeof us === "string") {
        const vs = JSON.parse(us);
        this.visibleTitle = !vs.visibleMenu;
        this.user = vs;
      }
      const token = localStorage.getItem("access_token");
      if (token != null && token !== typeof undefined && us) {
        this.logged = true;
      }
    },
    changePassword() {
      this.loadingAction = true;
      http.put(`/auth/me/change-password`, this.pass).then(
        (res) => {
          localStorage.setItem("token", res.data.access_token);
          const user = JSON.stringify(res.data.user);
          localStorage.setItem("user", user);

          this.snackbar.text = "Senha alterada com sucesso!";
          this.snackbar.color = "green";
          this.snackbar.opened = true;
          this.loadingAction = false;
          this.changePasswordDialog = false;
          this.forcePass = false;
        },
        (err) => {
          const { response } = err;
          this.snackbar.text = "Falha ao atualizar a senha: " + response.data.message;
          this.snackbar.color = "red";
          this.snackbar.opened = true;
          this.loadingAction = false;
        }
      );
    },
    openChangeDialog() {
      this.changePasswordDialog = true;
    },
  },
  mounted() {
    this.init();
  },
});
</script>
