<template>
  <div class="menu">
    <div class="text-center">
      <div>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text small color="white" v-bind="attrs" v-on="on">
              <v-icon size="34">mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-card color="#050A30" style="padding: 20px" class="my-list">
            <v-row>
              <v-col cols="12" v-for="(menu, mI) in menuList" :key="mI">
                <v-list>
                  <h6 style="padding-left: 15px !important; color: grey">
                    {{ menu.label }}
                  </h6>
                  <v-list-item v-for="(max, m) in menu.subMenu" :key="m">
                    <v-btn text :to="max.link" style="text-transform: none !important">
                      <v-list-item-title :to="max.link">{{
                        max.label
                      }}</v-list-item-title>
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import { MenuFilter } from "./MenuService";
export default Vue.extend({
  name: "Menu",
  data: () => ({
    showMenu: false,
    loading: false,
    minMenu: [],
    menuFilter: [],
    user: {
      visibleMenu: true,
      darkTheme: false,
      admin: false,
      group: {
        permissions: [],
      },
    },
    menuList: MenuFilter,
  }),
  methods: {
    navigateApp() {
      window.open("https://app.gerentor.com.br");
    },
  },
});
</script>
<style>
.menu {
  height: auto !important;
  justify-items: end;
  max-width: 3rem;
  background-color: #1a265a !important;
}
.my-list {
  height: calc(100vh - 200px);
  overflow-y: auto;
}
</style>
